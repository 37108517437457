@import '../../assets/global.scss';
@import '../../assets/variables.scss';
@import '../../assets/mixins.scss';

.Social{display:inline-block;
  li {display:inline-block;margin:0 5px;
    &:last-child{margin-right:0;}
  }
  a, span {color:$black;}
  svg {width:18px;height:18px;}

  &.header svg{width:16px;height:16px;}
  &.drawer{
    svg{width:20px;height:20px;}
    li:last-child{margin-left:7px;}
  }
  
  &.round {
    li{text-align:center;background-color:#c1e6e1;border-radius:50%;width:32px;height:32px;}
    span{color:darken($teal,10%);margin-top:5px;}
    li,a,span{line-height:1em;}
  }

  // @media (max-width:959px){&.header svg{width:15px;height:15px;}}
}