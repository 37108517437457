// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

.modalDialog{width:99%;max-width:950px !important;padding:40px 30px 30px;
  p.h3{font-size:22px;font-weight:900;margin-bottom:20px;text-align:center;}
  .tborder{@include sec-default();}
 
  span:first-child{display: none;}
  p{font-size:18px;}
  &:not(.bio) p:last-child{font-size:16px;font-weight:bold;}

  @include bp(br){p.h3{font-size:19px;}}
  @include bp(og){padding:15px;}
  @include bp(og){padding:40px 10px 20px;}
}

.bioModal .modalDialog{padding:40px 0 25px;
  p:first-child, img{display:none;}
  p+div{display: none;}
  p{font-size:17px;margin-bottom:20px;}

  @include bp(oc){
    img{width:90%;max-width:300px;@include center-block();}
  }
}
