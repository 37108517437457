$fa: #fafafa;
$f0: #f3f3f3;
$white: #fff;
$black: #000;

$color1:#e64e25;
$pink:#e31a4c;
$tpink:#be2d55;
$red: #ed343a;
$orange:#e64e25;
$sun:#f47e22;
$purp:#662d91;
$teal:#15a18e;
$blue:#1e87f0;
$mag:#c71e5f; //#931d57;
$tealLt:#f0f9f7;
$ftColor:#e7f5f3;

$borderB: 1px solid rgba($black,.1);
$borderW: 1px solid rgba($white,.1);

$nun:'Nunito', sans-serif;
$Saira:'Saira', sans-serif;
// $star:'Hello-Star';
$Handlee: 'Handlee', cursive;



$body: $nun;
$emph:$Handlee;