@mixin center-block() {
  float:none;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@mixin sec-default() {
  width: 100%;
  float: left;
  display: block;
}

@mixin flex-default() {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@mixin sec-grid($float, $size) {
  display: inline-block;
  width: $size;
  float: $float;
}

@mixin sec-bgimage ($img) {
  background-image: url($img);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
}
@mixin sec-bgimage2() {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
}

@mixin button($bg, $color) {
  background-color:$bg;text-align:center;color:$color;font-weight:500;line-height:1em;font-size:15px;text-shadow:0px 1px 2px rgba($black,.1);padding:11px 20px 10px;display:inline-block;
  box-shadow:0 10px 25px rgba($black,.15);letter-spacing:2px;text-transform:uppercase;position: relative;z-index:0;overflow: hidden !important;transition: background .2s ease 0s;

  &::after{content:"";position:absolute;top:0;left:150%;width:200%;height:100%;transform: skewX(-20deg); 
    background-image:linear-gradient(90deg, transparent, rgba($white, 0.25), transparent);
  }

  &:hover::after{animation:shine 0.75s cubic-bezier(0.01, 0.56, 1, 1);}
  &:hover{background:lighten($bg, 10%);color:$color;opacity:1 !important;}
  &:active{transition-delay:0s;}
}
@keyframes shine{
  to{left:-200%;}
}

@mixin tshadowl($glow) {
  text-shadow: 0px 1px 2px $glow;
}

@mixin tshadowc($px, $glow) {
  text-shadow: $px $glow;
}

@mixin inherit(){
  color:inherit; font-size:inherit; font-weight:inherit; font-style:inherit;
}

@mixin visuallyHidden {
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  position: absolute;
  height: 1px;
  width: 1px;
}

@mixin before() {
  content: "";
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height:100%;
  z-index: -1;
}

@mixin after() {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height:100%;
  z-index: -1;
}

@mixin abs() {
  content: "";
  position: absolute;
  z-index: -1;
}

@mixin stackB() {
  position:relative;z-index:0;
  &::before{@include before();}
}
@mixin stackA() {
  position:relative;z-index:0;
  &::after{@include after();}
}
@mixin stackAbs() {
  position:relative;z-index:0;
  &::before{@include abs();}
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  -moz-transform: rotate($deg);
  -o-transform: rotate($deg);
  -ms-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin grayscale() {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
}

@mixin cloud() {
  background: linear-gradient(to right, rgba($white, 0),rgba($white, .5), rgba($white,1));
}

@mixin ease($speed) {
  -webkit-transition: $speed;
  -moz-transition: $speed;
  transition: $speed;
}

@mixin bp($point) {
  @if $point == su {
    @media (min-width: 1450px) { @content ; }
  }

  @else if $point == dt {
    @media (min-width: 1201px) { @content ; }
  }
  
  @else if $point == tl {
    @media (max-width: 1366px) { @content ; }
  }
  
  @else if $point == tx {
    @media (max-width: 1365px) { @content ; }
  }

  @else if $point == tw {
    @media (max-width: 1200px) { @content ; }
  }

  @else if $point == xl {
    @media (min-width: 1025px) { @content ; }
  }

  @else if $point == lg {
    @media (min-width: 768px) { @content ; }
  }

  @else if $point == tb {
    @media (max-width: 1024px)  { @content ; }
  }

  @else if $point == br {
    @media (max-width: 1023px)  { @content ; }
  }

  @else if $point == nn {
    @media (max-width: 989px)  { @content ; }
  }

  @else if $point == th {
    @media (max-width: 959px) { @content ; }
  }

  @else if $point == tn {
    @media (max-width: 823px) { @content ; }
  }

  @else if $point == md {
    @media (max-width: 768px) { @content ; }
  }

  @else if $point == og {
    @media (max-width: 767px) { @content ; }
  }

  @else if $point == pl {
    @media (max-width: 737px) { @content ; }
  }

  @else if $point == mb {
    @media (max-width: 670px) { @content ; }
  }

  @else if $point == oc {
    @media (max-width: 639px) { @content ; }
  }

  @else if $point == ph {
    @media (max-width: 570px) { @content ; }
  }

  @else if $point == fi {
    @media (max-width: 500px) { @content ; }
  }

  @else if $point == sm {
    @media (max-width: 420px) { @content ; }
  }

  @else if $point == xs {
    @media (max-width: 376px) { @content ; }
  }

  @else if $point == xx {
    @media (max-width: 320px) { @content ; }
  }
}