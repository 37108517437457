@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Footer{@include sec-default();position:relative;z-index:0;overflow:hidden;
  @include sec-bgimage('./images/footerBlaze.jpg');background-position:bottom right;background-size:cover;min-height:900px;
  &::before{@include before();width:106%;left:-3%;height:107%;box-shadow: inset 0px 0px 20px 25px $white;z-index:1;}

  .FooterBody{position:absolute;z-index:1;width:100%;left:0;bottom:30px;
    p.secTitle{text-shadow:0px 0px 1px $teal;position:absolute;
      font-size:35px;
      font-size:33px;
    }
  }

  .top,.btm{@include sec-default();margin-left:20px;}
  .top{margin-top:-110px;
    .social, .contact{display:inline-block;}
    .contact{float:left;
      p{display:inline-block;margin-right:50px;}
    }
    p{font-family:$emph;font-size:23px;text-shadow:0px 0px 1px $teal;}
    p span{font-size:20px;}
  }
  a,p{color:darken($teal,10%);}
  a{font-weight:bold;}
  p{line-height:1.1em;}
  li:first-child{margin-left:0;}
  p.copy{font-size:15px;margin-bottom:0;}


  .quote{position:absolute;width:450px;top:15%;left:5%; // display:none;
    // p:not(.auth){font-size:28px;font-family: $emph;}
    // p.auth{font-size:18px;}
    width:80%;max-width:600px;
    p{font-size:23px;font-family:$emph;text-shadow:0px 1px 1px rgba($black,.3);line-height:1.3em;}
  }

  .FooterWave{width:100%;z-index:-1;position:absolute;overflow:hidden;
    .ftWave{transform:scaleY(1.3);}
    path{fill:$ftColor;}
    
    &.Lwave{bottom:0;}
    &.Twave{top:0;transform:rotateX(180deg);width:80%;}
  }



  @media (min-width:768px){.FooterBody p.secTitle{@include rotate(-90deg);height:40px;margin:0;left:-65px;width:200px;bottom:58px;}}
  @media (max-width:1500px){min-height:760px; 
    .top{margin-top:-90px;}
    .ftWave{transform: scaleY(1.6);}
  }
  @include bp(tw){min-height:650px;
    .FooterBody{bottom:15px;
      p.secTitle{font-size:30px;bottom:70px;}
    }
    .FooterWave .ftWave{transform: scaleY(1.5);}
  }
  @include bp(tb){min-height:550px; 
    .FooterWave .ftWave{transform: scaleY(1.6);min-height:200px;}
  }
  @media (max-width:959px){min-height:480px;
    .FooterBody p.secTitle{font-size:26px;}
    .top p{font-size:21px;}
    .top p span{font-size:19px;}
    p.copy{font-size:14px;}
  }
  @include bp(md){min-height:420px; .FooterWave .ftWave{min-height:220px;}}
  @include bp(og){min-height:100%;background-position:center center;padding:20px 0 5px;text-align:center;
    &::after{@include after();left:0;background-color:rgba($ftColor,.8);}
    .FooterWave{display:none;}

    .FooterBody{position:relative;bottom:auto;
      p.secTitle{font-size:24px;position:relative;margin:30px 0 18px;bottom:auto;}
    }

    .inside{width:95%;}
    .btm{margin-left:0;}
    .top{margin:0 0 20px 0;}

    .contact, .social{@include sec-default();}
    .contact p{@include sec-default();margin:0 0 15px;}

    p.copy{line-height:1.4em;}
  }

  @include bp(oc){
    p.copy{max-width:300px;@include center-block();}
  }
}