// @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&family=Saira:wght@300;400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&family=Saira:wght@300;400;600;800&family=Handlee&display=swap');

@import "./assets/global";
@import "./assets/variables";
@import "./assets/mixins";

*, *::before, *::after{box-sizing:border-box;}
body{margin:0;}

html{
  &, h1, h2, h3, h4, h5, p, li{color:$black;line-height:1.5em;font-family:$body;font-weight:300;}
  h1, h2, h3, h4, h5, p, li{margin:0 0 10px;}
  &,p{font-size:19px;}
  
  h1{font-size:45px;font-weight:900;font-family:$emph;line-height:1.2em;}
  h2{font-size:22px;}
  h3{font-size:25px;}
}

a,button{text-decoration:none;
  &:hover{opacity:.7;text-decoration:none;@include ease(.3s);}
}
button{background:none;border:none;border-radius:0;color:inherit;font-weight:inherit;font-family:inherit;
  &:hover{cursor:pointer;}
}
img{max-width:100%;height:auto;display:block;}
ul{margin:0;list-style-type:none;padding-left:0;}
li{list-style-type:none;}

.blazeWrap{@include sec-default();position:relative;}
.Content{@include sec-default();
  @media (min-width:992px){min-height:55vh;}
  @media (min-width:1024px){min-height:82vh;}
}

.ToTopBtn{
  background-color:$color1 !important;right:10px !important;
  text-align:center;border-radius:50%;box-shadow:0px 10px 20px rgba($black,.2);
  svg{margin-bottom:4px;fill:$white;width:18px;}
  
  &:hover{opacity:.8 !important;}

  // @include bp(tl) {bottom:50px !important;}
  @include bp(br) {bottom:30px !important;}
  @include bp(oc) {bottom:45px !important;}
}