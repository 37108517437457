@import '../../assets/global';
@import '../../assets/variables';
@import '../../assets/mixins';

.Header{@include sec-default();padding:5px 0;position:relative;z-index:1;
  &,li,a{font-family:$emph;color:$black;}
  .mobNav{display:none;position:relative;z-index:3;}
  .navArea{float:none;text-align:center;}
  .navArea li{display:inline-block;vertical-align:top;margin:0 15px;font-size:21px;
    // //&.homelink{display:none;}
    &.drawer{display:none;}
  }

  .HeaderCurve{position:absolute;top:0;z-index:-1;display:none;path{fill:$tealLt;}}
  .HeaderWave{width:80%;z-index:-1;position:absolute;top:0;transform:rotateX(180deg);
    .hdWave{min-height:200px;transform: scaleY(1.2);}
    path{fill:$tealLt;}
  }
  .hdCol{display:inline-block;vertical-align:top;}
  .colLogo{margin-right:2%; margin-right:10px;}
  .colNav{margin-top:65px;}
  .infoRow{position:absolute;z-index:1;top:10px;width:80%;right:3%;text-align:right;font-size:18px;letter-spacing:.5px;}
  .logoImg{max-width:200px;}
  .callLink{margin-right:20px;font-size:17px;}
  .Email{margin-right:12px;}
  .clearfix{display:none;}
  .navMob{display:none;}
  
  @media (min-width:1600px){.infoRow{right:5%;}}
  @media (max-width:1450px){.infoRow{right:2%;}}
  @include bp(tl){
    .HeaderWave .hdWave{min-height:230px;transform:scaleY(1.6);}
    .colLogo{margin-right:5px;}
    .infoRow{right:1%;}
  }
  @include bp(tw){
    .HeaderWave{width:75%;
      .hdWave{transform:scaleY(2.3);}
    }
    .hdCol{@include sec-default();max-width:380px;}
    .clearfix{display:block;}
    .logoImg{@include center-block();max-width:180px;}
    .colNav{margin-top:20px;}
    .colLogo{margin-right:0;}
    .navArea li{margin:0 10px;}
    .navArea li:first-child{margin-left:0;}
    .navArea li:last-child{margin-right:0;}
  }
  @include bp(tb){
    .insideXl{width:98%;}
    .HeaderWave{width:70%;
      .hdWave{min-height:240px;transform:scaleY(2.7);}
    }
    .navArea li{font-size:20px;}
  }

  @media (max-width:959px){
    .insideXl{max-width:300px;float:left;margin-left:25px;}
    .HeaderWave{width:70%;
      .hdWave{min-height:180px;transform:scaleY(3.2);}
    }

    .colNav,.clearfix{display:none;}
    .colLogo,.mobNav{float:none;margin:0 20px;}
    .mobNav{display:inline-block;margin-top:15%;}
    .colLogo{max-width:100%;width:auto;}
    .logoImg{float:left;margin-left:0;}
    .infoRow{position:relative;padding:0 0 0 25px;top:auto;right:auto;margin-bottom:10px;width:100%;text-align:left;}
  }

  @include bp(tn){
    .insideXl{margin-left:10px;}
    .HeaderWave{width:72%;
      .hdWave{min-height:200px;transform:scaleY(3.3);}
    }
    .infoRow{padding-left:15px;}
  }

  @include bp(og){
    .HeaderWave{width:70%;
      .hdWave{min-height:190px;transform:scaleY(3.5);}
    }
    .infoRow ul{display:none;}
    .Email{margin-right:0;}
    .logoImg{max-width:155px;}
    .mobNav{margin:15% 0 0 15px;}
  }

  @include bp(oc){
    .HeaderWave{width:100%;overflow:hidden;
      .hdWave{width:500px;transform:scaleY(3);}
    }
    .infoRow{margin-bottom:5px;}
  }
  @include bp(fi){padding:8px 0 15px;
    background-color:$tealLt;
    .HeaderWave{display:none;}
    // .HeaderWave .hdWave{min-height:150px;transform:scaleY(2.5);}
    .insideXl{margin-left:auto;float:none;}
    .logoImg{max-width:140px;}
    .mobNav{margin:12% 0 0 10px;}
    .infoRow{display:none;}
  }

  // @include bp(xs){
    // .HeaderWave .hdWave{min-height:230px;transform:scaleY(2.5);}
  // }
}

.menuIcon{@include sec-default();fill:$black;height:28px;
  svg{width:25px;height:25px;}
}
.SideNavList{padding:40px 30px 0;max-width:300px;
  .SideNav{margin-top:30px;}
  .closeIcon{color:$black;position:absolute;right:10px;top:15px;cursor:pointer;font-weight:normal;
    svg{width:30px;height:30px;}
  }

  img{max-width:150px;margin-top:-30px;}

  .navItem,p{@include sec-default();margin:0 0 30px;line-height:1em;
    a, button{font-weight:bold;color:$black;text-transform:none;font-size:21px;line-height:1em;font-family:$emph;letter-spacing:1px;}
    button{padding:0;}
    span{font-size:15px;font-weight:normal;color:$black;font-style:italic;text-transform:none;}
  }
  hr{margin-bottom:30px;opacity:.7;}
  .contactSec{@include sec-default();
    p, a{font-size:20px;}
  }
}