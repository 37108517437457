// background: linear-gradient(to bottom left, cyan 50%, palegoldenrod 50%);
@import "../../assets/global";
@import "../../assets/variables";
@import "../../assets/mixins";

p.auth{@include sec-default();margin-top:5px;font-size:17px;font-weight:400;text-transform:uppercase;letter-spacing:1px;}
.HomePage{@include sec-default();position:relative;z-index:0;
  @media (min-width:1800px){
    .v1outer{width:100%;max-width:1800px;@include center-block();}
  }
  .homeSec1{@include sec-default();position:relative;z-index:0;padding:85px 0 250px; 
    &::after{@include after();left:0;width:35%;bottom:-70px;@include sec-bgimage('./images/adobeTrailC.png');background-position: bottom left;opacity:.15;}

    h1.mobile{display:none;}
    .text,.image{display:inline-block;}
    .text{width:45%;float:left;margin-left:-4%;margin-top:15%;}
    
    h2 b{color:$mag;}
    .text p{font-weight:400;}

    .imageTwist{float:right;position:relative;z-index:0;margin-top:50px;@include rotate(-5deg);width:62%;margin-right:-8%;
      &::before{@include before();top:-22px;height:50px;@include sec-bgimage('./images/pawColor2.png');background-size:40px;background-position:top center;z-index:1;}
      button{z-index:5;}
      &:hover button{background-color:rgba($black,.2);color:$white;}
    }
    .imageTwistMob{display:none;
      button{display:none;}
    }

    .imageTwist2{position:absolute;z-index:0;@include rotate(10deg);width:550px;right:3%;top:62%;
      @include sec-bgimage('./images/supplies1.jpg');background-size:cover;
      &::before{@include before();z-index:1;background-image:linear-gradient(to right, rgba($white,.9), rgba($ftColor, 0.9), rgba($f0,.9));}
   
      .quote{position:relative;z-index:2;padding:40px 10px 55px 20px;
        p:not(.auth){font-family:$emph;font-size:19px;margin-bottom:0;}
        p.auth{font-weight:bold;font-size:14px;}
      }
    }

    .cta{@include sec-default();margin-top:15px;}
    .blazeLink{border:1px solid $color1;margin-left:15px;}

    
    @media (min-width:1680px){.imageTwist2{right:3%;top:57%;}}

    @media(max-width:1300px) {
      &::after{width:40%;bottom:-40px;}
      .text{width:40%;margin-left:-3%;}
      .imageTwist{width:58%;margin-right:-2%;}

      .imageTwist2{width:650px;right:3%;top:62%;
        .quote{padding:30px 10px 45px 20px;}
      }
      h1{font-size:40px;}
      a{padding-left:15px;padding-right:15px;}
    }

    @include bp(tw){padding:20px 0 60px;
      &::after{width:40%;bottom:30px;}
      .text{width:42%;margin-left:0;margin-top:12%;}
      .imageTwist{width:53%;margin-right:0;margin-top:0;}
      
      .imageTwist2{position:relative;@include sec-default();top:auto;right:auto;margin-top:80px;@include rotate(5deg);
        .quote{padding:30px 20px 40px;}
      }

      h1{font-size:35px;}
      h2{font-size:20px;}
      .text p{font-size:18px;}
      a{font-size:14px;}
    }

    @include bp(tb){
      &::after{width:45%;bottom:10px;}
      .imageTwist::before{top:-18px;background-size:30px;}
      .imageTwist button{top:90%;}
    }

    @include bp(br){padding:20px 0 50px;
      h1{font-size:33px;}
      h2{font-size:19px;}
      .text p{font-size:17px;}

      a:first-child,.blazeLink{padding:10px 10px 9px;}
      .blazeLink{max-width:220px;margin-left:10px;}
      a:first-child{max-width:135px;}
    }

    @include bp(th){z-index:2;padding:20px 0 60px;
      &::after{bottom:20%;}
      .medDef{max-width:720px;}

      .imageTwist2{display:none;}
      .imageTwist{width:50%;margin-top:-60px;z-index:2;width:58%;}
      .text{@include center-block();width:100%;margin-top:0;text-align:center;max-width:600px;}

      h1.fw{display:none;}
      h1.mobile{display:inline-block;}
      h1{font-size:31px;margin-top:12%;width:42%;margin-left:-1%;}
      // h2{@include sec-default();font-size:18px;margin-top:50px;}
      .bod{@include sec-default();margin-top:50px;}
      h2{font-size:18px;}
      .cta{margin-top:5px;}
      a:first-child,.blazeLink{float:none;margin:5px 10px;}
    }

    @include bp(tn){.imageTwist .arrowLeft{display:none;}}

    @include bp(md){
      &::after{width:60%;bottom:30%;}
      h1{font-size:27px;margin-left:0;}
    }

    @include bp(og){padding:20px 0 40px;
      &::after{bottom:50%;}
      .medDef{max-width:550px;}
      .imageTwist{width:57%;margin-right:-3%;margin-top:-30px;
        &::before{top:-10px;background-size:20px;}
      }
      h1{width:48%;margin-left:-2%;}
      .text{max-width:550px;}
    }

    @include bp(oc){padding:40px 0;
      &::after{width:50%;}
      .imageTwist{display:none;}
      .imageTwistMob{@include sec-default();margin:35px 0 10px;position:relative;z-index:1;
        &::before{@include before();top:-12px;height:40px;@include sec-bgimage('./images/pawColor2.png');background-size:20px;background-position:top center;z-index:3;}
        .itSlideWrap{@include center-block();width:95%;max-width:390px;@include rotate(-5deg);position:relative;z-index:0;}
      }
    
      h1.mobile{@include sec-default();margin:10px 0;text-align:center;position:relative;z-index:3;font-size:24px;}
      .bod{margin-top:0px;}
      p.h3{font-size:20px;}
    }

    @include bp(fi){padding-top:10px;&::after{width:60%;}}
  }

  .homeSecServe{@include sec-default(); padding-bottom:180px;margin-top:-30px;margin-bottom:40px; position:relative;z-index:0;overflow: hidden;
    &::after{@include abs();width:38%;height:80%;right:0;top:5%; 
      @include rotate(15deg);
      @include sec-bgimage('./images/adobeTrailCrev.png');background-position:bottom right;opacity:.1;
    }
    .rel{position:relative;z-index:0;}
    .sl1{@include sec-default();text-align:center;}
    h3{font-size:40px;font-weight:bold;font-family:$emph;margin-bottom:0;}
    .tabNav{max-width:700px;@include center-block();margin:0 auto 40px;text-align:center;flex-wrap: nowrap;
      &::before{display:none;}
      li{padding:24px 15px 0;display:inline-block;
        &::before{@include before();left:0;background-position:top center;background-size:20px;background-repeat:no-repeat;}
      }
      button{font-size:24px;font-family:$emph;}
    }

    .tabText,.image{display:inline-block;}
    .tabText{width:40%;float:right;margin-top:10%;margin-right:-5%;}
    .groom .tabText{margin-top:5%;}
    .imageTwist{width:60%;float:left;position:relative;z-index:0;}
    .imageTwist2{
      position:absolute;z-index:1;
      width:485px;max-height:180px;overflow:hidden;
      bottom:-130px;left:-6%; @include rotate(-10deg);
      &::before{@include before();z-index:1;background-image:linear-gradient(to right, rgba($white,.9), rgba($ftColor, 0.9), rgba($f0,.9));}
    }
    .it2Box{position:absolute;z-index:2;top:40px;}
    ul.serviceDets{text-align:center;
      li{font-size:23px;line-height:1.2em;font-family:$emph;text-transform:capitalize;
        display:inline-block;position:relative;z-index:0;margin:2px 0 5px;padding:0 15px;
        &::after{@include after();width:5px;height:5px;left:-3px;bottom:50%;background-color:$black;border-radius:50%;}
        &:first-child::after,&:nth-child(4)::after,&:nth-child(6)::after{display:none;}
      } 
    }


    .topText{@include sec-default();margin-bottom:20px;}
    p.h4{font-family:$emph;margin-bottom:0;font-size:23px;line-height:1.3em;font-weight:bold;}
    .highlight{color:$teal;}
    p.highlight{font-weight:500;}
    .serveHlf{width:90%;@include center-block();
      p:not(.h4){margin-bottom:2px;font-size:21px;}
    }
    .cats{margin-top:20px;}
    .trim{width:95%;}
    .event,.corp{
      p:not(.h3){font-size:21px}
    }
    .clearfix{display:none;}

    @include bp(tw){padding-top:30px;margin-bottom:0px;
      &::after{width:50%; @include rotate(10deg);}
      h3{font-size:35px;}
      .tabNav{margin-bottom:25px;
        button{font-size:22px;}
      }
      .tabText{width:42%;margin-right:-4%;margin-top:5%;}
      .groom .tabText{margin-top:3%;}
      .imageTwist{width:58%;}

      .serveHlf p:not(.h4),.event p:not(.h3),.corp p:not(.h3){font-size:20px;}
      .trim{width:90%;}

      ul.serviceDets li{font-size:21px;}

      .imageTwist2{
        width:485px;max-height:165px;
        bottom:-110px;left:-3%; @include rotate(-10deg);
      }
      .it2Box{top:30px;}
    }

    @include bp(tb){&::after{width:55%;}}
    @include bp(th){margin-bottom:0px;}
    @include bp(nn){padding-bottom:40px;
      &::after{width:50%;}
    
      .medDef{max-width:700px;}
      .sl1{margin-bottom:20px;}

      h3{font-size:28px;}
      .tabNav{margin-bottom:0px;
        li{padding-top:18px;}
        li::before{background-size:16px;}
        button{font-size:21px;font-weight:bold;}
      }

      .tabText{width:100%;max-width:600px;@include center-block();margin:0 auto 15px;padding:0;text-align:center;}
      .groom .tabText{margin-top:0;}
      .imageTwist{display:none;}
      
      .topText,.corp{margin-bottom:0;}
      p.h3{font-size:22px;max-width:100%;@include center-block();margin-bottom:20px;
        br{display:block;}
      }

      p.h4{font-size:21px;}
      .serveHlf p:not(.h4), .event p:not(.h3), .corp p:not(.h3){font-size:19px;}
      .trim{width:100%;}

      .imageTwist2{position:relative;top:auto;@include center-block();margin-top:20px;right:auto;bottom:unset;width:100%;@include rotate(0);
        @include sec-bgimage('./images/supplies1Mobile.jpg');height:100%;max-height:100%;padding:30px 10px 20px;background-size:cover;
        img{display:none;}
      }
      .it2Box{position:relative;@include sec-default();top:auto;}
      ul.serviceDets{@include center-block();max-width:600px;
        li{font-size:19px;
          &::after{width:3px;height:3px;right:-1px;}
          &:nth-child(4)::after, &:nth-child(6)::after{display:block;}
          &:nth-child(5)::after{display:none;}
        }
      }
    }

    @include bp(th){padding:20px 0 30px;background-color: $fa;}
    @include bp(md){
      .medDef{max-width:550px;}
      .sl1{margin-bottom:10px;}
      h3{font-size:25px;}
      p.h3{font-size:21px;}
      p.h4{font-size:20px;}
      .serveHlf p:not(.h4),.event p:not(.h3),.corp p:not(.h3){font-size:18px;}
      p.highlight{font-size:16px;}
      ul.serviceDets{
        li:nth-child(5)::after{display:block;}
        li:nth-child(4)::after{display:none;}
      }
    }
    @include bp(og){
      .tabNav button{font-size:20px;}
      p.h3{max-width:500px;font-size:19px;}
      p.h4{font-size:18px;}
      .serveHlf p:not(.h4),.event p:not(.h3),.corp p:not(.h3){font-size:17px;}
      p.highlight{font-size:15px;}
    }

    @include bp(oc){
      h3{font-size:23px;}
      .tabNav{background-color:rgba($fa,.9);padding:5px 0 10px;margin-bottom:5px;
        li{margin-bottom:0;z-index:1;padding:13px 5px 0;}
        li::before{background-size:12px;}
        button{font-size:18px;}
      }
      p.h3{max-width:100%;font-size:18px;
        br{display:block;}
      }
    }

    @include bp(fi){
      .imageTwist2{padding:20px 0;}
      ul.serviceDets{max-width:310px;
        li{font-size:18px;}
      }
    }

    @media (max-width:340px){
      ul.serviceDets li{padding:0 8px;}
      ul.serviceDets li::after{right:0;}
    }
  }

  .quoteLax{@include sec-default();text-align:center;overflow:hidden;padding:110px 0 85px;@include sec-bgimage('./images/supplies2.jpg');position:relative;z-index:0;
    &::before{@include before();background-color:rgba($fa,.83);}
    &::after{@include after();width:104%;left:-2%;box-shadow: inset 0px 0px 20px 25px $white;}
    
    .relative{position:relative;}
    .slItems li{width:100%;display:flex;align-items:center;}

    p.review{font-family:$emph;font-size:21px;font-weight:bold;line-height:1.7em;margin-bottom:0;}
    li.short .inLi{@include center-block();max-width:750px;}
    p.auth{font-size:16px;}

    .dots{margin-top:0 !important;margin-bottom:0;
      a{border-color:rgba($black,.4);}
    }
    .arrowLeft{margin-left:30px;}
    .arrowRight{margin-right:30px;}

    @include bp(tb){padding:90px 0 70px;
      p{font-size:20px;}
      li:not(.short) p{@include center-block();width:90%;}
    }

    @include bp(th){display:none;}
  }

  .fullImageRow{display:none;
    @include bp(th){
      @include sec-default();text-align:center;
      .imageMob{display:inline-block;vertical-align:top;border-right:4px solid $white;border-left:4px solid $white;
        &.img2{width:65.7%;border-right:2px solid $white;}
        &.img1{width:34.3%;border-left:2px solid $white;}
      }
    }

    @include bp(og){.irWrap{@include center-block();max-width:600px;}}
  }

  .about{@include sec-default();padding:60px 0 100px;text-align: center;position:relative;z-index:1;
    // &::before{@include before();opacity:.1;
    //   @include sec-bgimage('./images/adobeTrailC.png');background-size:38%;background-position: top left;
    // }
    .abItem{display:inline-block;text-align:left;position:relative;z-index:0;}
    .accordTitle, .accordContent{margin:0;padding:0;width:100%;text-align:left;}
    .clearfix.ten{display:none;}

    .i1{width:37%;}
    .i2, .i3{width:37%;margin-top:150px;}
    .i2{float:left;margin-left:-10%;padding-right:10px;}
    .i3{float:right;margin-right:-11%;}
    .absBox{width:380px;position:absolute;left:50%;margin-left:-205px;margin-top:60px;
      p:first-child{font-weight:bold;color:$color1;}
      p.h3{margin-bottom:5px;}
      p.cert2{font-size:21px;font-weight:bold;}
    }

    p.cert{margin-top:30px;font-weight:bold;display:none;
      span{font-family:$emph;font-size:25px;letter-spacing:1px;}
    }
    p.h3 br{display:none;}
    button p{color:$teal; font-weight:bold;}

    @media (min-width:960px){
      .accordTitle{position:relative;z-index:0;
        &::before{display:none;}
        &:hover{cursor:default;color:inherit;opacity:1;}
      }
      .abItem::after{@include before();background-color:transparent;z-index:1;}
      .accordContent{display:block !important;}
    }

    @media(max-width:1325px) {
      .i1{width:35%;margin-left:-2%;}
      .i2{width:35%;margin-left:-4%;}
      .i3{width:33%;margin-right:-4%;}
    }

    @include bp(tw){padding-bottom:60px;
      .medDef{max-width:980px;}
      .i1{width:41%;float:right;}
      .i2, .i3{width:55%;float:left;margin:0;}
      .i3{padding-top:25px;}
      .absBox{width:42%;position:relative;left:auto;margin-left:0;margin-top:25px;display:inline-block;
        padding:20px 10px 15px;margin-top:10px;
        @include sec-bgimage('./images/babyGroom.jpg');
        &::before{@include before();z-index:1;background-image:linear-gradient(to right, rgba($white,.9), rgba($ftColor, 0.9), rgba($f0,.9));}
        p{position:relative;z-index:2;}
      }
      .absBox{float:right;}
      p.h3{margin-bottom:0px;}
    }

    @include bp(tb){padding:30px 0 30px;
      .medDef{max-width:980px;}
      .i1,.absBox{width:45%;}
      .i2,.i3{width:50%;}
      .absBox{margin-top:25px;
        p.cert2{font-size:19px;}
      }
      p.h3{font-size:24px;}
    }

    @include bp(br){.i2{width:51%;}}

    @include bp(th){margin-top:0px;padding-bottom:20px;
      .medDef{max-width:620px;}
      .clearfix.ten{display:none;}
      ul{max-width:600px;@include center-block();}
      .i1,.i2,.i3{width:100%;float:left;margin:0 0 10px;padding:0;}
      .accordContent{padding-bottom:10px;
        p{font-size:18px;}
      }
      .absBox{@include sec-default();padding:20px 20px 15px;margin-top:10px;}
      p.h3{font-size:22px;}
    }

    @include bp(oc){
      .absBox p.cert2{font-size:17px;}
      p.h3{font-size:20px;letter-spacing:.3px;}
    }
  }

  .teamOuter{max-width:1800px;
    @include bp(og){width:100%;}
  }
  .team{@include sec-default(); padding:70px 0 100px; margin-bottom:50px;position:relative;z-index:0;
    @include sec-bgimage('./images/blazeVan3.jpg');background-position:top right;background-size:cover;
    &::before{@include before();background-color:rgba($fa,.95);}
    &::after{@include before();width:100%;box-shadow: inset 0px 0px 20px 25px $white;}

    h3{font-size:35px;font-family:$emph;margin-bottom:80px;text-align:center;font-weight:bold;}
    p{font-size:18px;}
    p.name{font-size:40px;font-family:$emph;color:$orange;margin:0;}
    .image p.name{position:absolute;font-weight:bold;}
    
    .item{@include sec-default();}
    .image, .text{display:inline-block;}
    .image{width:40%; position: relative;z-index: 0;}
    .text{width:55%;margin-top:10%;
      p.name{display:none;}
    }
    .more{display:none;
      button{font-size:14.5px;color:$blue;font-weight:bold;padding:0;}
    }

    .blaze{
      .text{float:right;}
      .image{float:left;}
      .image p.name{left:-15px;top:10px;@include rotate(-25deg);}
    }
    .liz{
      .text{float:left; text-align: right;}
      .image{float:right;}
      .image p.name{right:-30px;@include rotate(30deg);}
    }

    @include bp(tw){margin-bottom:30px;
      h3{font-size:31px;margin-bottom:60px;}
      .teamWrap{width:92%;}
      .blaze .text{margin-top:5%;}
      .liz{margin-top:20px;}
    }

    @media (max-width:1050px){padding-top:60px;
      .teamWrap{width:90%;}
      h3{font-size:29px;margin-bottom:40px;}
      p{font-size:17px;}
      p.name{font-size:32px;}

      .item{display:flex;align-items:center; justify-content:space-between;}
      .text{ margin-top:unset;}
      .blaze .text{margin-top:unset;}
      .liz{margin-top:0;}
      .more{display:block;}
      .mobHide{display:none;}
    }

    @include bp(th){padding-bottom:80px;margin-bottom:0px;
      &::before{background-color:rgba($ftColor, 0.9); opacity:.97;
        background-image:linear-gradient(rgba($fa, .9), rgba($ftColor, 1));
      }
    }
    @include bp(og){padding:45px 0 60px;
      background-image:url('../../assets/images/blazeVan3mob.jpg');
      .teamWrap{width:94%;}
      h3{font-size:24px;margin-bottom:20px;}
      p{font-size:16px;}
      .image p.name{display:none;}
      p.name{font-size:19px;}

      .image{width:37%;}
      .text{width:60%; margin-top:unset;
        p.name{display:block;}
      }
      .liz{margin-top:20px;}
    }
    @include bp(oc){padding:30px 0 20px;
      .teamWrap{width:90%;}
      h3{font-size:20px;margin-bottom:15px;}
      p.name{font-size:18px;}

      .item{align-items:start;}
      .liz{margin:40px 0;}
      .image{width:30%;}
      .text{width:67%;}
    }

    @media (max-width:450px){
      .text .name+p{margin-bottom:5px;}
    }
  }

  .quoteLaxMobile{display:none;text-align:center;position:relative;z-index:0;margin-top:30px;
    .relative{position:relative;}
    .insideNrw{max-width:780px;}

    p.review{font-family:$emph;font-size:17px;font-weight:bold;line-height:1.7em;margin-bottom:0;}
    p.auth{font-size:14px;}
    .dots{margin-top:0 !important;margin-bottom:0;
      a{border-color:rgba($black,.4);}
    }
    .arrowLeft{margin-left:5px;}
    .arrowRight{margin-right:5px;}
    .arrowLeft, .arrowRight{
      svg{height:17px;color:$black;}
    }

    li{width:100%;text-align:left;display:flex;align-items:center;padding-left:15px;padding-right:15px;}
    .dblSpacer{@include sec-default();height:15px;}
    li.double{display:block;
      p:last-child{margin-bottom:0;}
    }
    p{text-shadow:1px 1px $white;}
    span.hideFull{display:none;}
    span.hideModal button{color:$teal;font-size:16px;font-weight:bold;}
    
    @include bp(th){@include sec-default();}
    @include bp(og){margin-top:20px;
      li{align-items:start;}
    }
  }
}