@import './variables.scss';
@import './mixins.scss';

@font-face {
  font-family: 'Hello-Star';
  src: url('./fonts/Hello-Star.ttf');
  src: 
    url('./fonts/Hello-Star.otf') format('otf'),
    url('./fonts/Hello-Star.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MoniqueScript-Regular';
  src: url('./fonts/MoniqueScript-Regular.eot');
  src: url('./fonts/MoniqueScript-Regular.eot?#iefix') format('embedded-opentype'),
       url('./fonts/MoniqueScript-Regular.woff') format('woff'),
       url('./fonts/MoniqueScript-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}


.insideXl,.insideN,.inside,.insideAlt,.medDef,.med2Def,.med3Def,.insideNrw,.medkDef{@include center-block();}
.inside,.insideAlt,.insideXl,.insideN{max-width:1700px;}
.medDef,.med2Def,.med3Def,.insideNrw{width:90%;}
.insideXl{width:95%;}
.insideN{width:90%;}
.inside{width:85%;}
.insideAlt{width:80%;}
.medDef{max-width:1080px;}
.medkDef{max-width:1000px;}
.med2Def{max-width:940px;}
.med3Def{max-width:850px;}
.insideNrw{max-width:820px;}

.fullRow{@include sec-default();}
.centerBlock{@include center-block();}
.bmargin0{margin-bottom:0;}
.bmargin05{margin-bottom:5px;}
.bmargin1{margin-bottom:10px;}
.tmargin0{margin-top:0;}
.tmargin2{margin-top:20px;}
.textCenter{text-align:center;}
.textLeft{text-align:left;}
.textRight{text-align:right;}
.tpadding4{padding-top:40px;}
.tpadding6{padding-top:60px;}

.spacer1{@include sec-default();height:10px;}
.spacer2{@include sec-default();height:20px;}
.spacer25{@include sec-default();height:25px;}
.spacer3{@include sec-default();height:30px;}

.up{text-transform:uppercase;}
.cap{text-transform:capitalize;}

@media (min-width:769px){.mdShow{display:none;}}
@media (max-width:768px){.mdHide{display:none;}}
@media (min-width:768px){.ogShow{display:none;}}
@media (max-width:767px){.ogHide{display:none;}}

p.h3{font-family:$emph;font-weight:bold;font-size:24px;}//text-transform:uppercase;}
.secTitle{font-family:$emph;font-size:35px;font-weight:900;}
.bold{font-weight:bold;}
.nun{font-family:$nun;}
.saira{font-family:$Saira;}
span.low{font-size:.9em;}
span.low2{font-size:.9em;padding-left:2px;}
span.lower{font-size:.8em;}

hr{border-color:rgba($black,.1);
	&.hlf{width:50%;}
	&.less{width:25%;}
}
.clearfix::after, .clearfixBtn::after{content: "";clear: both;display: table;}
.clearfix.btn, .clearfixBtn {@include sec-default();margin-top:20px;}

.btnRow{@include sec-default();margin-top:15px;}
.blazeLink{@include button($color1, $white);}
.blazeLinkRev{@include button($white, $color1);text-shadow:none;
  border:1px solid rgba($color1,.5);
  &::after{background-image:linear-gradient(90deg, transparent, rgba($color1, 0.25), transparent);}
}

.btnIcon{width:15px;display:inline;margin-left:5px;}

.textLink{font-weight:600;color:$color1;
  &.und{text-decoration:underline;}
  &:hover{color:darken($color1,15%) !important; opacity:1;}
}

.visuallyHidden{@include visuallyHidden();}
ul.disc li{list-style-type:disc;}